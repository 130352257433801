@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width :)  {
    
}

.Tab__Component__First nav {
    @apply w-1/4
    
}
.AnkaP span{
    position: relative;
}

.vertical-timeline--animate::before{
    @apply !bg-black
}

.active {
    @apply transition-all duration-300 bg-main-blue text-white
}
.For__Links li a{
    @apply px-4 py-2  !w-full
}

.Block__Padding {
    @apply py-12 sm:py-24 
}

.slick-dots{
  @apply absolute top-48
}

#docs-sidebar {
  @apply max-sm:!fixed max-sm:!top-[170px] 
}

.Block__Padding .slick-slider{

    @apply shadow-input flex justify-center !items-center rounded-2xl max-xl:mx-5 
}
.opacitybg {
    background: rgba(0, 0, 0, 0.6);
}

.react-tabs__tab--selected div {
    @apply bg-white border-2 !border-[#f1efee] 
}
.tabs .ul li .absolute{
    @apply shadow-2xl rounded-3xl 
}

.porc {
    all: unset;
    width: 100px;
    height: 30px;
    font-size: 16px;
    background: transparent;
    border: none;
    position: relative;
    color: #f0f0f0;
    cursor: pointer;
    z-index: 1;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  
  
 
  
  .porc::after {
    transform: translate(10px, 10px);
    width: 35px;
    height: 35px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
  }
  

  
  .porc:active::after {
    transition: 0s;
    transform: translate(0, 5%);
  }
  
  
  
  .active2::after {
    border-radius: 10px;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
  }

  .secondul li a{
    @apply text-main-text  relative uppercase font-bold after:content-[''] after:absolute after:bottom-0 after:w-0 after:left-0 after:border-b after:h-0 after:border-main-text 
    before:content-[''] before:absolute before:top-0 before:w-0 before:right-[-50%] before:border-b before:h-0 before:border-main-text
  }

  .secondul li .active2{
    @apply after:w-[150%]  after:duration-700 relative 
    before:w-[150%]  before:duration-700  
  }
  